// THIS PAGE SHOULD BE REMOVED BEFORE DEPLOYMENT
import { ReactElement } from 'react';
import CabNavBar from '@CabComponents/CabNavBar';
import { cabCaptureMessage } from '../utils/logging';


const TestPage = (): ReactElement => {
  /* (BEGIN) DO NOT REMOVE - used for testing Sentry integration */
  if (window.location.search && window.location.search.startsWith('?test-sentry')) {
    const testMsg = 'Testing Sentry. Param = ' + window.location.search;
    console.log(testMsg);
    cabCaptureMessage(testMsg);
  }
  /* (END) DO NOT REMOVE - used for testing Sentry integration */

  return (<>
    <CabNavBar />
  </>);
};

export default TestPage;

